import RecipesList from "../components/RecipesList";

const SearchResults = () => {
  return (
    <>
      {" "}
      <div>
        <h1>Recipes</h1>
        <RecipesList />
      </div>
    </>
  );
};

export default SearchResults;

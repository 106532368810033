//const apiKey = '13b5df97cb5d4a71b3d59df42370950b';
//const apiKey='7319a32be1a545548851c4026953363d';
//const apiKey='b9ea1c49200244408739478c1dfcb08a';
//const apiKey = '7246d79eea914495a90cdf0baa623801';
//const apiKey = '8bd2ff4d3ce94648929cff82009aea1f';
//const apiKey='823b7958b6ef41d59b30f0feb9d1c483';
//const apiKey = '9644a762ea774bfba37662fe05b957c4';
//export default apiKey;

export const url = 'https://api.lovefoodapp.co.uk';
//export const url = 'http://localhost:6001';

export const getApiKey = () => {

    const keys = [
        '13b5df97cb5d4a71b3d59df42370950b',
        '7319a32be1a545548851c4026953363d',
        'b9ea1c49200244408739478c1dfcb08a',
        '7246d79eea914495a90cdf0baa623801',
        '8bd2ff4d3ce94648929cff82009aea1f',
        '823b7958b6ef41d59b30f0feb9d1c483',
        '9644a762ea774bfba37662fe05b957c4'
    ];

    const random = Math.floor(Math.random() * keys.length - 1);
    // console.log(keys[random], random)
    return keys[random];
}